<template>
  <nav
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container mt-6">
      <div class="columns is-mobile">
        <div class="column is-half">
          <router-link
            class="navbar-item"
            :to="{ name: 'home' }"
          >
            <img
              src="/storage/images/htx_logo_web.png"
              alt="PROM-select app | https://www.prom-select.eu | HTx Next Generation Health Technology
Assessment; linking to home"
              title="HTx Project"
            >
          </router-link>
        </div>

        <div class="column has-text-right">
          <router-link
            v-if="$route.name === 'home'"
            tag="button"
            class="button is-info"
            :to="{ name: 'proms' }"
          >
            {{ $t('nav.proms') }}
          </router-link>

          <router-link
            v-else
            tag="button"
            class="button is-info"
            :to="{ name: 'home' }"
          >
            {{ $t('nav.home') }}
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',

  data() {
    return {
      isActive: false,
    };
  },

  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
  nav img {
    max-height: none;
  }

  .navbar-item:hover {
    background-color: transparent;
  }

  .navbar-burger {
    margin-left: auto;
  }
</style>
