import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Prom from '../views/Prom.vue';
import Proms from '../views/Proms.vue';

function castRouteParams(route) {
  return {
    id: Number(route.params.id),
  };
}

Vue.use(VueRouter);

const baseTitle = 'PROM select app';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: `${baseTitle} | Home`,
    },
  },
  {
    path: '/proms/:query?',
    name: 'proms',
    component: Proms,
    props: true,
    meta: {
      title: `${baseTitle} | Proms`,
    },
  },
  {
    path: '/proms/view/:id',
    name: 'prom',
    component: Prom,
    props: castRouteParams,
    meta: {
      title: `${baseTitle}`,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
