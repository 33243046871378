<template>
  <div id="skipLink">
    <div class="container">
      <p>
        <a href="#contentWrapper">Skip to content</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkipLink',
};
</script>
