<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <div
          v-if="isLoading"
          class="notification"
        >
          {{ $t('loading') }}
        </div>

        <template v-else>
          <Name :name="prom.name" />
          <Link :link="prom.link" />
          <Other
            :fields="prom.other"
            :source="prom.source"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Name from '../components/Name.vue';
import Link from '../components/Link.vue';
import Other from '../components/Other.vue';
import text from '../mixins/text';

export default {
  name: 'Prom',

  components: {
    Name,
    Link,
    Other,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  mixins: [
    text,
  ],

  computed: {
    ...mapGetters({
      prom: 'proms/prom',
    }),

    isLoading() {
      return this.prom === null;
    },
  },

  created() {
    this.$store.dispatch('proms/load', this.id);
  },

  watch: {
    prom: {
      handler() {
        document.title = `${this.$route.meta.title} | ${this.prom.name}`;
      },
    },
  },
};
</script>
