const locales = {
  en: {
    nav: {
      home: 'Home',
      proms: 'To select app',
      back: 'Back to index',
    },
    meta: {
      title: 'PROM Select App',
      description: 'The PROM toolbox consists of the PROM-guide, step 3 accompanied by the literature review on the use of PROMs, and the PROM-cycle of which step 2 and 3 are supplemented with generic PROMs in the Linnean menu. In addition, the PROM-overview is an Excel database containing PROMs accompanied by relevant information made available via a user-friendly web-application helping users to select PROMs: this PROM-select app.',
    },
    result: {
      none: 'None selected',
      clear: 'Clear search query',
      found: 'Found no PROMs | Found 1 PROM | Found {count} PROMs',
      result: 'Search results',
      selectedDomains: 'Selected Patient Reported Outcome',
      selectedCaseStudies: 'Selected health problem or patient group/population',
      noSelection: 'No selection made, choose 1 or more Patient Reported Outcome(s) and/or 1 or more health problem(s) from the items above',
    },
    search: {
      label: 'Search in proms',
      placeholder: 'Enter search query here',
    },
    select_domains: 'Select Patient Reported Outcome',
    select_caseStudies: 'Select health problem or patient group/population',
    loading: 'Fetching data...',
    questionnaire: 'Questionnaire',
    link: 'Link',
    source: 'Source',
  },
};

export default locales;
